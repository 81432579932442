
























import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { CommunityDetailViewModel } from '../../viewmodels/community-detail-viewmodel'

@Observer
@Component({
  components: {
    'apply-to-join-dialog': () =>
      import('@/modules/community-organize/components/private-dao/apply-to-join-dialog.vue'),
  },
})
export default class ApplyToJoinButton extends Vue {
  @Inject() vm!: CommunityDetailViewModel
  @Prop({ default: false }) disabled?: boolean
}
